////DEV
let baseApi = "https://action-api-dev.ontrak.onsite.com.au"
export const environment = {
    production: true,
    apiUrl: `${baseApi}/api/`,
    rootUrl: `${baseApi}`,
    webRootUrl: 'https://action-dev.ontrak.onsite.com.au/',
    clientId: "f1a39844-e6a2-4ae8-a950-f206aeb29ab9",
    main_tenant: "cac",
    allApi_scopes:"user.read",

    AZURE_MAPS_KEY_S0: 'yrx22IOCByHcz00LU5pDaAFivncULW-zU3NotgSWopM',
    AZURE_MAPS_KEY_S1: 'Gel1nnWWoEvYE4vhBGrcVAGaN585IMkiguKnQZ7xuy8',

    AZURE_APPLICATION_ID : '5a6fd2cb-9526-40e8-bc03-0ec565c8542e',
    AZURE_AUTHORITY: 'https://login.microsoftonline.com/3e1945df-bfc5-4443-bea2-5b3305b889d5/',
    AZURE_APP_API_PERMISSION: 'api://5a6fd2cb-9526-40e8-bc03-0ec565c8542e/read.all',
    GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
    authorityDomain: "login.microsoftonline.com",
};
