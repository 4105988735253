import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { LayoutService, ILayoutConfig } from '@itm/services';

export const LayoutResolver: ResolveFn<ILayoutConfig> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const _layoutService = inject(LayoutService);
    const pageKey:string = route.data['pageKey'];
    _layoutService.setCurrentActivatedRoute(pageKey);
    _layoutService.setToolbarButtonClick(null);
    _layoutService.setToolbarFormButtonClick(null);
    return _layoutService.layoutConfig$;
};