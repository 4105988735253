import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, catchError, map, of } from 'rxjs';
import { HttpErrorService } from '@itm/services';
import { DeleteResult, GetResult, PostResult } from '@itm/types';

@Injectable({
  providedIn: 'root'
})
export class ApiHttpService {
  private readonly apiUrl: string = '';
  // private readonly rootUrl: string = '';
  // private readonly webRootUrl: string = '';
  // private readonly identityUrl: string = '';
  private readonly http = inject(HttpClient);
  private readonly errorService = inject(HttpErrorService);
  constructor() {
    this.apiUrl = environment.apiUrl;
    // this.rootUrl = environment.rootUrl;
    // this.webRootUrl = environment.webRootUrl;
    // this.identityUrl = environment.identityServiceRootUrl;
  }
  GET_API_URL(): string {
    return this.apiUrl;
  }
  // GET(url: string): Observable<any> {
  //   return this.http.get(url, { responseType: 'json' })
  //     .pipe(
  //       catchError(err => this.errorService.formatError(err))
  //     );
  // }
  GETT<T>(url: string): Observable<GetResult<T>> {
    return this.http.get<T>(`${this.apiUrl}${url}`, { responseType: 'json' })
      .pipe(
        map(resp => { 
          return ({ data: { data: resp, error: null } } as GetResult<T>)
        }),
        catchError(err => {
          throw 'error in source. Details: ' + err;
        })
      );
  }
  // GETT2<T>(url: string): Observable<GetResult<T>> {
  //   return this.http.get<T>(`${url}`, { responseType: 'json' })
  //     .pipe(
  //       map(resp => ({ data: resp, error: null } as GetResult<T>)),
  //       catchError(err => of({
  //         data: undefined,
  //         error: this.errorService.formatError(err)
  //       } as GetResult<T>))
  //     );
  // }

  POST<T>(url: string, data: any): Observable<PostResult<T>> {
    return this.http.post<T>(`${this.apiUrl}${url}`, data)
      // return this.http.post<T>(`${url}`, data)
      .pipe(
        map(resp => ({ data: resp, error: null } as PostResult<T>)),
        catchError(err => of({
          data: undefined,
          error: this.errorService.formatError(err)
        } as PostResult<T>))
      );
  }
  PUT<T>(url: string, data: any): Observable<PostResult<T>> {
    return this.http.put<T>(`${this.apiUrl}${url}`, data)
      // return this.http.put<T>(`${url}`, data)
      .pipe(
        map(resp => ({ data: resp, error: null } as PostResult<T>)),
        catchError(err => of({
          data: undefined,
          error: this.errorService.formatError(err)
        } as PostResult<T>))
      );
  }
  DELETE<T>(url: string, data: any): Observable<DeleteResult<T>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.request("DELETE", `${this.apiUrl}${url}`, {
      headers: headers,
      body: data
    }).pipe(
      map(resp => ({ data: resp, error: null } as DeleteResult<T>)),
      catchError(err => of({
        data: undefined,
        error: this.errorService.formatError(err)
      } as DeleteResult<T>))
    );
  }
}
